<template>
  <FieldMultiSelect
    :label="label"
    :state="state"
    :disabled="disabled"
    :is-required="isRequired"
    :class-label="classLabel"
    :class-wrapper="classWrapper"
    :emit-value="true"

    :placeholder="placeholder"

    option-value="id"
    option-label="name"
    v-model="selected"

    :use-input="true"
    :allow-add-options="false"
    :load-options="loadOptions"
  />
</template>

<script>
import api from '@/aws/api'
import FieldMultiSelect from '@/components/forms/FieldMultiSelect'

let agencies = []

export default {
  components: {
    FieldMultiSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    },

    classWrapper: {
      type: String
    },

    extraField: {}
  },

  data () {
    const vm = this
    const isString = typeof vm.value === 'string'
    const isNumber = typeof vm.value === 'number'

    let selected = []
    let loadAfter = isString || isNumber ? String(vm.value).trim().split(',').filter(v => v.trim()) : []

    if (loadAfter.length) {
      loadAfter = loadAfter.join(',')
    } else {
      selected = toSelected(this.value)
    }

    return {
      selected,
      loadAfter
    }
  },

  mounted () {
    const vm = this
    vm.startLoadAfter()
  },

  computed: {
    placeholder () {
      const hasValue = Array.isArray(this.selected) && this.selected.length
      return hasValue ? '' : 'Selecionar'
    },

    regions () {
      return Array.isArray(this.extraField) ? this.extraField.map(({ id }) => id) : []
    }
  },

  methods: {
    async startLoadAfter () {
      const vm = this

      if (!agencies.length) {
        await api.getAgencies().then(({ rows }) => {
          agencies = rows.map((row) => ({
            id: row.id,
            name: row.name
          }))
        })
      }

      if (!vm.loadAfter.length) return
      const selected = vm.loadAfter.map((id) => agencies.find((item) => item.id === Number(id)))
      if (isEqual(vm.selected, selected)) return
      vm.selected = selected
    },

    async loadOptions (val) {
      if (!agencies.length) {
        await api.getAgencies().then(({ rows }) => {
          agencies = rows.map((row) => ({
            id: row.id,
            name: row.name
          }))
        })
      }

      const arg = toLower(val)
      if (!arg) return [...agencies]
      const options = agencies.filter((item) => toLower(item.name).includes(arg))
      return options
    }
  },

  watch: {
    selected (selected) {
      if (isEqual(selected, this.value)) return
      this.$emit('input', toSelected(selected))
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        const isString = typeof value === 'string'
        const isNumber = typeof value === 'number'
        const loadAfter = isString || isNumber ? String(vm.value).trim().split(',').filter(v => v.trim()) : []

        if (loadAfter.length) {
          vm.loadAfter = loadAfter.join(',')
          vm.startLoadAfter()
        } else {
          if (isEqual(this.selected, value)) return
          this.selected = toSelected(value)
        }
      }
    }
  }
}

const toSelected = (val) => {
  if (Array.isArray(val)) return [...val]
  return val ? [val] : null
}

const toLower = (str) => (
  String(str || '')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
)

const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
</script>
