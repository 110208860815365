<template>
  <q-card
    flat
    bordered
  >
    <q-card-section
      class="q-pa-sm"
      ref="areaTitle"
    />

    <q-card-section class="q-pa-none">
      <div class="row full-width">
        <div
          class="col"
          style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
        >
          <DashLoadTable
            v-if="tableHeight && filters"
            :height="tableHeight"
            :get-data="getData"
            :columns="columns"
            :filters="filters"
            :item-size="65"
          />
        </div>

        <div class="col-xs-6 col-sm-5 col-md-4 col-lg-3 column">
          <span class="col-auto filter-label">
            Filtros
          </span>

          <q-scroll-area
            v-if="tableHeight && filters"
            :style="`height: ${tableHeight - 27}px`"
          >
            <FieldRegions
              label="Região"
              v-model="filters.regions"
              class-wrapper="q-px-sm q-py-xs"
            />

            <FieldCores
              label="Núcleo"
              v-model="filters.cores"
              class-wrapper="q-px-sm q-py-xs"
              :extra-field="filters.regions"
            />

            <FieldDegrees
              label="Grau"
              v-model="filters.degrees"
              class-wrapper="q-px-sm q-py-xs"
            />

            <FieldAgencies
              label="Órgão Emissor"
              v-model="filters.agencies"
              class-wrapper="q-px-sm q-py-xs"
            />

            <FieldDocuments
              label="Documento"
              v-model="filters.documents"
              class-wrapper="q-px-sm q-py-xs"
            />

            <FieldDateRange
              label="Período de Acesso"
              v-model="filters.access"
              class-wrapper="q-px-sm q-py-xs"
            />

            <FieldDateRange
              label="Período de Cadastros"
              v-model="filters.register"
              class-wrapper="q-px-sm q-py-xs"
            />
          </q-scroll-area>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import api from '@/aws/api'
import DashLoadTable from '../components/DashLoadTable'
import FieldCores from '@/modules/documents/components/FieldCores'
import FieldDateRange from '@/components/forms/FieldDateRange'
import FieldDegrees from '@/modules/documents/components/FieldDegrees'
import FieldRegions from '@/modules/documents/components/FieldRegions'
import FieldAgencies from '@/modules/documents/components/FieldAgencies'
import FieldDocuments from '@/modules/documents/components/FieldDocuments'
import { isEqual, toQueryFilters, formatInt } from '../components/utils'
import { REPORT_AFFILIATES } from '../constants'
let lastRequest = null

export default {
  components: {
    DashLoadTable,

    FieldCores,
    FieldDegrees,
    FieldRegions,
    FieldAgencies,
    FieldDocuments,
    FieldDateRange
  },

  props: {
    height: {}
  },

  data () {
    const tableHeight = 0
    const filters = null
    return { filters, tableHeight }
  },

  mounted () {
    const vm = this
    lastRequest = null

    vm.$nextTick().then(() => {
      vm.applyFilter()
      vm.setInterval = setInterval(vm.checkHeight, 1500)
      vm.checkHeight()
    })
  },

  beforeDestroy () {
    const vm = this
    if (vm.setInterval) clearInterval(vm.setInterval)
  },

  computed: {
    columns () {
      return [
        { name: 'name', field: 'name', align: 'left', label: 'Sócio', sortable: true },
        { name: 'views', field: 'views', align: 'right', label: 'Visualização', sortable: true, headerClasses: 'q-table--col-auto-width', format: formatInt },
        { name: 'favorites', field: 'favorites', align: 'right', label: 'Favoritado', sortable: true, headerClasses: 'q-table--col-auto-width', format: formatInt },
        { name: 'downloads', field: 'downloads', align: 'right', label: 'Downloads', sortable: true, headerClasses: 'q-table--col-auto-width', format: formatInt },
        { name: 'accessedAt', field: 'accessedAt', align: 'left', label: 'Acesso', sortable: true, headerClasses: 'q-table--col-auto-width' },
        { name: 'registeredAt', field: 'registeredAt', align: 'left', label: 'Cadastrado', sortable: true, headerClasses: 'q-table--col-auto-width' },
        { name: 'coreName', field: 'coreName', align: 'left', label: 'Núcleo', sortable: true },
        { name: 'phone', field: 'phone', align: 'left', label: 'Contato', sortable: false, headerClasses: 'q-table--col-auto-width' }
      ].filter(v => v)
    }
  },

  methods: {
    checkHeight () {
      const vm = this
      let tableHeight = vm.height

      if (vm.$refs.areaTitle) {
        const { clientHeight: areaTitleHeight } = vm.$refs.areaTitle.$el
        tableHeight = vm.height - areaTitleHeight
      }

      if (tableHeight !== vm.tableHeight) vm.tableHeight = tableHeight
    },

    applyFilter (force = false) {
      const vm = this
      if (vm.filters && !force) return
      const filters = clearFilter(vm.$route.query)
      if (typeof filters.register === 'string') filters.register = filters.register.split(',')
      if (typeof filters.access === 'string') filters.access = filters.access.split(',')
      vm.filters = filters
    },

    getData ({ sort, desc, page, limit }) {
      const vm = this
      const filters = { ...toQueryFilters(vm.$route.query), page, limit }
      if (sort) filters.sort = sort
      if (sort && desc) filters.desc = desc

      if (lastRequest) api.cancel(lastRequest)
      lastRequest = api.getDashAffiliates(filters)

      return lastRequest.then((res) => {
        lastRequest = null
        return res
      })
    }
  },

  watch: {
    '$route' (to) {
      const vm = this
      if (to.name !== REPORT_AFFILIATES) return
      const query = clearFilter(toQueryFilters(vm.filters))
      const oldQuery = clearFilter(vm.$route.query)
      if (isEqual(query, oldQuery)) return
      this.applyFilter(true)
    },

    filters: {
      deep: true,

      handler (filter) {
        const vm = this
        const { name } = vm.$route
        const query = clearFilter(toQueryFilters(filter))
        const oldQuery = clearFilter(vm.$route.query)
        if (isEqual(query, oldQuery)) return
        vm.$router.push({ name, query }).catch(() => null)
      }
    }
  }
}

const clearFilter = ({ ...filters }) => {
  for (const attr in filters) {
    const isInvalidAttr = !['regions', 'cores', 'degrees', 'agencies', 'documents', 'access', 'register'].includes(attr)
    if (isInvalidAttr) delete filters[attr]
  }

  return filters
}

</script>
