<template>
  <FieldMultiSelect
    :label="label"
    :state="state"
    :disabled="disabled"
    :is-required="isRequired"
    :class-label="classLabel"
    :class-wrapper="classWrapper"
    :placeholder="placeholder"
    :emit-value="true"

    option-value="id"
    option-label="name"
    v-model="selected"

    :use-input="true"
    :allow-add-options="false"
    :load-options="loadOptions"
  />
</template>

<script>
import FieldMultiSelect from '@/components/forms/FieldMultiSelect'

export default {
  components: {
    FieldMultiSelect
  },

  props: {
    value: {},

    state: {
      type: Object
    },

    label: {
      type: String
    },

    isRequired: {
      type: Boolean
    },

    disabled: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String
    },

    classWrapper: {
      type: String
    },

    extraField: {}
  },

  data () {
    const degrees = [
      { id: 1, name: 'QS - Quadro de Sócios' },
      { id: 2, name: 'CI - Corpo Instrutivo' },
      { id: 3, name: 'CDC - Corpo do Conselho' },
      { id: 4, name: 'QM - Quadro de Mestres' }
    ]

    const vm = this
    const isString = typeof vm.value === 'string'
    const isNumber = typeof vm.value === 'number'

    let selected = []
    const loadAfter = isString || isNumber ? String(vm.value).trim().split(',').filter(v => v.trim()) : []

    if (loadAfter.length) {
      selected = loadAfter.map((id) => degrees.find((item) => item.id === Number(id)))
    } else {
      selected = toSelected(vm.value)
    }

    return {
      degrees,
      selected
    }
  },

  computed: {
    placeholder () {
      const hasValue = Array.isArray(this.selected) && this.selected.length
      return hasValue ? '' : 'Selecionar'
    },

    regions () {
      return Array.isArray(this.extraField) ? this.extraField.map(({ id }) => id) : []
    }
  },

  methods: {
    async loadOptions (val) {
      const vm = this
      const arg = toLower(val)
      if (!arg) return vm.degrees
      return vm.degrees.filter((item) => toLower(item.name).includes(arg))
    }
  },

  watch: {
    selected (selected) {
      if (isEqual(selected, this.value)) return
      this.$emit('input', toSelected(selected))
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        const isString = typeof value === 'string'
        const isNumber = typeof value === 'number'
        const loadAfter = isString || isNumber ? String(vm.value).trim().split(',').filter(v => v.trim()) : []

        if (loadAfter.length) {
          vm.selected = loadAfter.map((id) => vm.degrees.find((item) => item.id === Number(id)))
        } else {
          if (isEqual(this.selected, value)) return
          this.selected = toSelected(value)
        }
      }
    }
  }
}

const toSelected = (val) => {
  if (Array.isArray(val)) return [...val]
  return val ? [val] : null
}

const toLower = (str) => (
  String(str || '')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
)

const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
</script>
