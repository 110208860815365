<template>
  <q-page :style-fn="getAreaDimension">
    <div
      class="column q-pa-sm dashpage"
      ref="areaBreadCrumb"
    >
      <q-toolbar class="bg-primary text-white rounded-borders q-px-xs q-py-none">
        <q-btn
          flat
          dense
          no-caps
          class="btndash"
          :label="labelReport"
        />

        <q-space />

        <q-tabs
          dense
          no-caps
          indicator-color="#f8f7fe"
          v-model="tab"
        >
          <q-route-tab
            :to="routeRegisters"
            @click="goToRegisters"
            name="registers"
            label="Cadastros"
          />

          <q-route-tab
            :to="routeAccess"
            @click="goToAccess"
            name="access"
            label="Acessos"
          />

          <q-route-tab
            :to="routeDocuments"
            @click="goToDocuments"
            name="documents"
            label="Documentos"
          />

          <q-route-tab
            :to="routeAffiliates"
            @click="goToSearch"
            name="affiliates"
            label="Sócios"
          />
        </q-tabs>
      </q-toolbar>
    </div>

    <div class="col max-container-width q-mx-auto dashpage q-px-sm q-py-none">
      <q-tab-panels
        v-if="formHeight"
        v-model="tab"
        animated
        keep-alive
        transition-prev="scale"
        transition-next="scale"
      >
        <q-tab-panel
          name="registers"
          class="q-pa-none"
        >
          <DashRegisters :height="formHeight" />
        </q-tab-panel>

        <q-tab-panel
          name="access"
          class="q-pa-none"
        >
          <DashUserAccess :height="formHeight" />
        </q-tab-panel>

        <q-tab-panel
          name="documents"
          class="q-pa-none"
        >
          <DashDocAccess :height="formHeight" />
        </q-tab-panel>

        <q-tab-panel
          name="affiliates"
          class="q-pa-none"
        >
          <DashAffiliates :height="formHeight" />
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </q-page>
</template>

<script>
import DashAffiliates from './DashAffiliates'
import DashRegisters from './DashRegisters'
import DashUserAccess from './DashUserAccess'
import DashDocAccess from './DashDocAccess'

import {
  REPORT_REGISTERS,
  REPORT_ACCESS,
  REPORT_DOCUMENTS,
  REPORT_AFFILIATES
} from '../constants'

export default {
  components: {
    DashAffiliates,
    DashRegisters,
    DashUserAccess,
    DashDocAccess
  },

  data () {
    const period = this.$route.query.periodo || '30dias'
    const formHeight = null
    const proxyDate = null
    const tab = 'registers'

    const routeRegisters = { name: REPORT_REGISTERS, query: { group: 'region' } }
    const routeAccess = { name: REPORT_ACCESS, query: { group: 'region' } }
    const routeDocuments = { name: REPORT_DOCUMENTS, query: { group: 'region' } }
    const routeAffiliates = { name: REPORT_AFFILIATES }

    return {
      tab,
      period,
      proxyDate,
      formHeight,

      routeRegisters,
      routeAccess,
      routeDocuments,
      routeAffiliates
    }
  },

  mounted () {
    const vm = this
    const isAdmin = vm.$store.getters.isAdmin
    if (!isAdmin) return vm.$router.go(-1)
  },

  computed: {
    labelReport () {
      const vm = this
      if (vm.tab === 'registers') return 'Relatório de cadastramento dos sócios'
      if (vm.tab === 'access') return 'Relatório de acesso dos sócios'
      if (vm.tab === 'documents') return 'Relatório de acesso aos documentos'
      if (vm.tab === 'affiliates') return 'Consulta de sócios'
      return 'Relatórios'
    }
  },

  methods: {
    getAreaDimension (offset, height) {
      const vm = this
      const areaHeight = height - offset
      if (areaHeight <= 0) return

      vm.$nextTick().then(() => {
        const { clientHeight: areaBreadCrumbHeight } = this.$refs.areaBreadCrumb
        vm.formHeight = areaHeight - areaBreadCrumbHeight - 5
      })
    },

    goToRegisters (e, go) {
      e.preventDefault()
      const vm = this
      go(vm.routeRegisters)
    },

    goToAccess (e, go) {
      e.preventDefault()
      const vm = this
      go(vm.routeAccess)
    },

    goToDocuments (e, go) {
      e.preventDefault()
      const vm = this
      go(vm.routeDocuments)
    },

    goToSearch (e, go) {
      e.preventDefault()
      const vm = this
      go(vm.routeAffiliates)
    }
  },

  watch: {
    '$route' (route) {
      const vm = this
      const query = { ...route.query }

      switch (route.name) {
        case REPORT_REGISTERS:
          vm.routeRegisters = { ...vm.routeRegisters, query }
          break

        case REPORT_ACCESS:
          vm.routeAccess = { ...vm.routeAccess, query }
          break

        case REPORT_DOCUMENTS:
          vm.routeDocuments = { ...vm.routeDocuments, query }
          break

        case REPORT_AFFILIATES:
          vm.routeAffiliates = { ...vm.routeAffiliates, query }
          break
      }
    }
  }
}

</script>

<style lang="sass">
  .dashpage
    .filter-label
      border-bottom: 1px solid rgba(0, 0, 0, 0.12)
      padding: 4px 8px
      font-weight: bold
      font-size: 0.9em

    .btndash
      font-weight: bold
      font-size: 1.1em

    .baranimated
      .q-linear-progress__stripe.q-linear-progress__stripe--with-transition.absolute-left
        animation: progress-bar-stripes 1s linear infinite
    .q-toolbar
      min-height: 36px
      .q-tab--active
        .q-tab__label
          font-weight: bold
          font-size: 1.03em

    @keyframes progress-bar-stripes
      0%
        background-position: 2.5rem 0
      100%
        background-position: 0 0

    .my-sticky-dynamic.q-table--dense
      .q-table
        td
          padding: 2px 8px
          margin: 0
        th
          padding: 2px 8px
          margin: 0
          font-weight: bold

      .q-table__top,
      .q-table__bottom,
      thead tr:first-child th
        background-color: #fff

      thead tr th
        position: sticky
        z-index: 1

      thead tr:last-child th
        top: 0
      thead tr:first-child th
        top: 0
      tbody
        scroll-margin-top: 37px

</style>
