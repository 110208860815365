import { render, staticRenderFns } from "./DashLoadTable.vue?vue&type=template&id=ff393fc6&"
import script from "./DashLoadTable.vue?vue&type=script&lang=js&"
export * from "./DashLoadTable.vue?vue&type=script&lang=js&"
import style0 from "./DashLoadTable.vue?vue&type=style&index=0&id=ff393fc6&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerIos from 'quasar/src/components/spinner/QSpinnerIos.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTd,QBtn,QIcon,QTooltip,QLinearProgress,QBadge,QInnerLoading,QSpinnerIos});
