<template>
  <div>
    <q-table
      dense
      flat
      ref="qTable"
      class="my-sticky-dynamic"
      virtual-scroll
      :virtual-scroll-item-size="itemSize"
      :virtual-scroll-sticky-size-start="itemSize"
      :pagination.sync="pagination"
      :columns="columns"
      :data="data"
      row-key="id"
      hide-bottom
      :sort-method="customSort"
      :rows-per-page-options="[0]"
      @request="onRequest"
      @virtual-scroll="onScroll"
      separator="cell"
      :table-style="{ height: `${height}px` }"
      binary-state-sort
    >
      <template #body-cell-btn="props">
        <q-td
          :props="props"
          auto-width
        >
          <q-btn
            dense
            rounded
            flat
            color="green-9"
            size="md"
            icon="mdi-account-search"
            @click="$emit('click-row', props.row)"
          />
        </q-td>
      </template>

      <template #body-cell-coreName="props">
        <q-td :props="props">
          <template v-if="props.row.regionName == props.row.coreName || !props.row.regionName">
            {{ props.row.coreName }}
          </template>
          <template v-else>
            <div class="column">
              <div class="col no-wrap">
                {{ props.row.coreName }}
              </div>
              <span class="col no-wrap">{{ props.row.regionName }}</span>
            </div>
          </template>
        </q-td>
      </template>

      <template #body-cell-name="props">
        <q-td :props="props">
          <div class="column">
            <div class="col no-wrap">
              {{ props.row.name }}
            </div>
            <small class="col no-wrap"><b>{{ props.row.degreeName }}</b></small>
          </div>
        </q-td>
      </template>

      <template #body-cell-documentDescription="props">
        <q-td :props="props">
          <div class="column full-width">
            <div class="col no-wrap">
              {{ props.row.documentDescription }}
            </div>
            <div
              class="col row no-wrap justify-between"
              style="font-size: 0.9em"
            >
              <b>{{ props.row.degreeName }}</b>
            </div>
          </div>
        </q-td>
      </template>

      <template #body-cell-accessedAt="props">
        <q-td :props="props">
          {{ formatDateTime(props.row.accessedAt) }}
        </q-td>
      </template>

      <template #body-cell-registeredAt="props">
        <q-td :props="props">
          {{ formatDateTime(props.row.registeredAt) }}
        </q-td>
      </template>

      <template #body-cell-phone="props">
        <q-td :props="props">
          <div class="column text-primary text-bold">
            <div
              class="col-auto q-py-xs"
              v-if="props.row.phone"
            >
              <q-btn
                flat
                dense
                padding="0 5px"
                @click="copy(props.row.phone)"
              >
                <div class="row">
                  <div class="col-auto q-pr-xs">
                    <q-icon
                      size="1em"
                      name="mdi-content-copy"
                      class="cursor-pointer"
                    />
                  </div>
                  <div class="col">
                    {{ props.row.phone }}
                  </div>
                </div>
                <q-tooltip>Clique aqui para copiar o telefone</q-tooltip>
              </q-btn>
            </div>
            <div
              class="col-auto q-pb-xs"
              v-if="props.row.email"
            >
              <q-btn
                flat
                dense
                no-caps
                padding="0 5px"
                @click="copy(props.row.email)"
              >
                <div class="row">
                  <div class="col-auto q-pr-xs">
                    <q-icon
                      size="1em"
                      name="mdi-content-copy"
                      class="cursor-pointer"
                    />
                  </div>
                  <div class="col">
                    {{ props.row.email }}
                  </div>
                </div>
                <q-tooltip>Clique aqui para copiar o e-mail</q-tooltip>
              </q-btn>
            </div>
          </div>
        </q-td>
      </template>

      <template #body-cell-percent="props">
        <q-td :props="props">
          <q-linear-progress
            stripe
            size="18px"
            :value="0.8"
            color="primary"
            track-color="blue-grey-5"
            class="baranimated"
            label="40%"
          >
            <div class="absolute-full flex flex-center">
              <q-badge
                color="white"
                text-color="primary"
                label="40%"
              />
            </div>
          </q-linear-progress>
        </q-td>
      </template>
    </q-table>

    <q-inner-loading
      :showing="loading"
      style="z-index: 9999"
    >
      <q-spinner-ios
        size="60px"
        color="primary"
      />
    </q-inner-loading>
  </div>
</template>

<script>
import { format } from 'timeago.js/dist/timeago.full.min'

export default {
  props: {
    columns: {},
    getData: {},
    height: {},
    filters: {},
    itemSize: {
      type: Number,
      default: 37
    }
  },

  data () {
    const data = []
    const loading = false
    const hasMore = true

    const pagination = {
      rowsPerPage: 30,
      rowsNumber: 0
    }

    return { data, pagination, loading, hasMore }
  },

  methods: {
    formatDateTime (unix) {
      if (!unix) return ''
      return format(unix * 1000, 'pt_BR')
    },

    customSort: (rows) => rows,

    onRequest ({ pagination }) {
      const vm = this
      const { sortBy, descending } = pagination
      const sort = sortBy
      const desc = !descending ? 'y' : undefined
      const limit = 30
      const page = Math.floor(vm.data.length / limit) + 1

      vm.loading = true
      const clearData = vm.pagination.sortBy !== pagination.sortBy || vm.pagination.descending !== pagination.descending

      if (clearData) {
        vm.data = []
        Object.assign(vm.pagination, { sortBy, descending })
      }

      vm.$nextTick().then(() => {
        vm.getData({ sort, desc, page, limit }).then(({ rows, count }) => {
          const ids = vm.data.map(({ id }) => id)
          vm.data = vm.data.concat(rows.filter(({ id }) => !ids.includes(id)))
          vm.hasMore = count > vm.data.length
          vm.loading = false
        }).catch(() => {
          vm.loading = false
        })
      })
    },

    async onScroll (item) {
      const vm = this
      if (vm.loading) return
      const to = item?.to ?? 0

      if (!vm.hasMore) return
      const lastIndex = vm.data.length - 1
      const canIgnore = (lastIndex - to) >= 7
      if (canIgnore) return

      const qTable = vm.$refs.qTable
      const pagination = { ...vm.pagination }
      qTable.requestServerInteraction({ pagination })
    }
  },

  watch: {
    filters: {
      deep: true,
      handler () {
        this.data = []
        this.hasMore = true
        this.onScroll()
      }
    }
  }
}

</script>
<style lang="sass">
  .my-sticky-dynamic
    div.no-wrap
      white-space: nowrap
</style>
