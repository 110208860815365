<template>
  <q-card
    flat
    bordered
  >
    <q-card-section
      class="q-pa-sm"
      ref="areaTitle"
    >
      <q-option-group
        dense
        size="xs"
        v-model="filters.group"
        :options="options"
        color="primary"
        inline
      />
    </q-card-section>

    <q-separator />

    <q-card-section class="q-pa-none">
      <div class="row full-width">
        <div
          class="col"
          style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
        >
          <DashLoadTable
            v-if="tableHeight && filters.group"
            :height="tableHeight"
            :get-data="getData"
            :columns="columns"
            :filters="filters"
            :item-size="itemSize"
            @click-row="onClickRow"
          />
        </div>

        <div class="col-xs-6 col-sm-5 col-md-4 col-lg-3 column">
          <span class="col-auto filter-label">
            Filtros
          </span>

          <q-scroll-area
            v-if="tableHeight"
            :style="`height: ${tableHeight - 27}px`"
          >
            <FieldRegions
              label="Região"
              v-model="filters.regions"
              class-wrapper="q-px-sm q-py-xs"
            />

            <FieldCores
              label="Núcleo"
              v-model="filters.cores"
              class-wrapper="q-px-sm q-py-xs"
              :extra-field="filters.regions"
            />

            <FieldDegrees
              label="Grau"
              v-model="filters.degrees"
              class-wrapper="q-px-sm q-py-xs"
            />

            <FieldDateRange
              label="Período de cadastro"
              v-model="filters.register"
              class-wrapper="q-px-sm q-py-xs"
            />
          </q-scroll-area>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import DashLoadTable from '../components/DashLoadTable'
import FieldCores from '@/modules/documents/components/FieldCores'
import FieldDateRange from '@/components/forms/FieldDateRange'
import FieldDegrees from '@/modules/documents/components/FieldDegrees'
import FieldRegions from '@/modules/documents/components/FieldRegions'
import api from '@/aws/api'
import { isEqual, toQueryFilters, formatInt } from '../components/utils'
import { REPORT_AFFILIATES, REPORT_REGISTERS } from '../constants'
let lastRequest = null

export default {
  components: {
    DashLoadTable,

    FieldCores,
    FieldDateRange,
    FieldDegrees,
    FieldRegions
  },

  props: {
    height: {}
  },

  data () {
    const tableHeight = 0
    const filters = {}

    const options = [
      { label: 'por Região', value: 'region' },
      { label: 'por Núcleo', value: 'core' },
      { label: 'por Grau', value: 'degree' },
      { label: 'Geral', value: 'general' }
    ]

    return { filters, options, tableHeight }
  },

  mounted () {
    const vm = this
    lastRequest = null

    vm.$nextTick().then(() => {
      const { name, query } = vm.$route
      if (!query.group) {
        vm.$router.replace({ name, query: { ...query, group: 'region' } }).catch(() => null)
      } else {
        vm.applyFilter()
      }
      vm.setInterval = setInterval(vm.checkHeight, 1500)
      vm.checkHeight()
    })
  },

  beforeDestroy () {
    const vm = this
    if (vm.setInterval) clearInterval(vm.setInterval)
  },

  computed: {
    itemSize () {
      const vm = this
      if (vm.filters.group === 'core') return 44
      return 37
    },

    label () {
      const vm = this
      if (vm.filters.group === 'core') return 'Núcleo'
      if (vm.filters.group === 'region') return 'Região'
      if (vm.filters.group === 'degree') return 'Grau'
      return 'Geral'
    },

    field () {
      const vm = this
      if (vm.filters.group === 'core') return 'coreName'
      if (vm.filters.group === 'region') return 'regionName'
      if (vm.filters.group === 'degree') return 'degreeName'
      return 'group'
    },

    columns () {
      const vm = this
      const label = vm.label
      const field = vm.field

      return [
        vm.filters.group !== 'general' ? { name: 'btn', field: 'btn', align: 'left', label: '', sortable: false } : null,
        { name: 'actives', field: 'actives', align: 'right', label: 'Ativos', sortable: true, headerClasses: 'q-table--col-auto-width', format: formatInt },
        { name: 'inactives', field: 'inactives', align: 'right', label: 'Inativados', sortable: true, headerClasses: 'q-table--col-auto-width', format: formatInt },
        { name: field, field, align: 'left', label, sortable: field !== 'group' }
        // { name: 'total', field: 'total', align: 'right', label: 'Sócios', sortable: true },
        // { name: 'percent', field: 'percent', align: 'left', label: 'Percentual', sortable: true }
      ].filter(v => v)
    }
  },

  methods: {
    checkHeight () {
      const vm = this
      let tableHeight = vm.height

      if (vm.$refs.areaTitle) {
        const { clientHeight: areaTitleHeight } = vm.$refs.areaTitle.$el
        tableHeight = vm.height - areaTitleHeight
      }

      if (tableHeight !== vm.tableHeight) vm.tableHeight = tableHeight
    },

    applyFilter () {
      const vm = this
      if (vm.filters.group) return
      const filters = clearFilter(vm.$route.query)
      if (typeof filters.register === 'string') filters.register = filters.register.split(',')
      if (typeof filters.access === 'string') filters.access = filters.access.split(',')
      vm.filters = filters
    },

    onClickRow (row) {
      const vm = this
      const query = clearFilter(vm.$route.query)
      const group = query.group || 'region'
      if (group === 'core') query.cores = row.id
      if (group === 'region') query.regions = row.id
      if (group === 'degree') query.degrees = row.id
      delete query.group
      vm.$router.push({ name: REPORT_AFFILIATES, query })
    },

    getData ({ sort, desc, page, limit }) {
      const vm = this
      const filters = { ...toQueryFilters(vm.$route.query), page, limit }
      if (sort) filters.sort = sort
      if (sort && desc) filters.desc = desc

      if (lastRequest) api.cancel(lastRequest)
      lastRequest = api.getDashRegisters(filters)

      return lastRequest.then((res) => {
        lastRequest = null
        return res
      })
    }
  },

  watch: {
    '$route' (to, from) {
      if (to.name !== REPORT_REGISTERS) return
      if (isEqual(to.query) === from.query) return
      this.applyFilter()
    },

    filters: {
      deep: true,

      handler (filter) {
        const vm = this
        const { name } = vm.$route
        const query = clearFilter(toQueryFilters(filter))
        const oldQuery = clearFilter(vm.$route.query)
        if (isEqual(query, oldQuery)) return
        vm.$router.push({ name, query }).catch(() => null)
      }
    }
  }
}

const clearFilter = ({ ...filters }) => {
  for (const attr in filters) {
    const isInvalidAttr = !['group', 'regions', 'cores', 'degrees', 'register'].includes(attr)
    if (isInvalidAttr) delete filters[attr]
  }

  return filters
}

</script>
