import numeral from 'numeral'
import 'numeral/locales/pt-br'
numeral.locale('pt-br')

export const formatInt = (val) => (
  numeral(Number(val)).format('0,0')
)

export const isEqual = (target, source) => (
  JSON.stringify(target) === JSON.stringify(source)
)

export const toQueryFilters = (filters) => (
  Object.keys(filters).reduce((acc, filterName) => {
    let value = filters[filterName]

    const isEmpty = [null, undefined, ''].includes(value)
    if (isEmpty) return acc

    if (Array.isArray(value)) value = value.map((val) => String(val?.id ?? val?.value ?? val).trim()).filter(v => v).join(',')
    if (!String(value).trim()) return acc

    acc[filterName] = value
    return acc
  }, {})
)
