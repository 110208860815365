import { render, staticRenderFns } from "./DashboardPage.vue?vue&type=template&id=24687e4a&"
import script from "./DashboardPage.vue?vue&type=script&lang=js&"
export * from "./DashboardPage.vue?vue&type=script&lang=js&"
import style0 from "./DashboardPage.vue?vue&type=style&index=0&id=24687e4a&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QToolbar,QBtn,QSpace,QTabs,QRouteTab,QTabPanels,QTabPanel,QLinearProgress,QTab,QTable});
