import { render, staticRenderFns } from "./DashDocAccess.vue?vue&type=template&id=e542bb08&"
import script from "./DashDocAccess.vue?vue&type=script&lang=js&"
export * from "./DashDocAccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QOptionGroup,QSeparator,QScrollArea,QTable});
